defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/TabsComponent', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {

  const initTabsNavigation = () => {
    const $tabComponents = document.querySelectorAll('.js-tabs-component'); // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

    if (!$tabComponents.length) {
      return;
    }

    $tabComponents.forEach(($tabComponent, i) => {
      const $tabContent = $tabComponent.querySelectorAll('.js-tab-frame'); // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
      const $tabNavLink = $tabComponent.querySelectorAll('.js-tab-nav-link'); // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

      if (!$tabContent.length) {
        return;
      }

      $tabContent[0].classList.add('is-active');

      if ($tabContent.length === 1) {
        const $content = $tabContent[0]; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
        const $title = document.createElement('h2'); // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

        $title.classList.add('avalon-tabs-component__frame-title');
        $title.textContent = $content.getAttribute('data-tabname');

        $content.prepend($title);

        $tabComponent.classList.add('tabs-component--tabs-disabled');
      } else {
        $tabContent.forEach(($content, index) => {
          const tabId = `tab-${i.toString()}_${index.toString()}`;
          const $tabLinkTitle = $tabNavLink[index].querySelector('.js-tab-link-title');

          if ($tabLinkTitle) {
            $tabLinkTitle.textContent = $content.getAttribute('data-tabname');
          }

          $content.setAttribute('id', tabId);

          $tabNavLink[index].setAttribute('href', `#${tabId}`);
          $tabNavLink[index].addEventListener('click', (ev) => {
            ev.preventDefault();

            $tabNavLink.forEach(($link) => {
              if ($link === ev.currentTarget) return;
              $link.parentElement.classList.remove('is-selected');
            });

            ev.currentTarget.parentElement.classList.add('is-selected');

            $tabContent.forEach(($tabElem) => {
              $tabElem.classList.remove('is-active');
            });

            $content.classList.add('is-active');
          });
        });
      }
    });
  };

  initTabsNavigation();

  if (document.querySelector('.js-tabs-component')) {
    Utils.logDeprecated('TabsComponent.js - ..js-tabs-component found'); // Added by MIPE 2024-05-14
  }
});
